<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <!--        <v-img
          :src="appLogo"
          max-height="150px"
          max-width="150px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>-->

        <!--        <h2 class="text&#45;&#45;primary">
          {{ appName }}
        </h2>-->
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <div class="d-flex align-center h-full pa-16 pe-0 mr-5">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="appLogo"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Bienvenu à Espas Moutier! 👋🏻
                  </p>
                  <p class="mb-2">
                    Connectez-vous
                  </p>
                </v-card-text>
                <v-alert
                  v-model="isAlertVisible"
                  :color="isErrorMessage ? 'error' : 'success'"
                  text
                  transition="slide-y-transition"
                >
                  {{ message }}
                </v-alert>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="login"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                      type="email"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Mot de passe"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      :rules="[rules.password_required]"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        v-model="isChecked"
                        hide-details
                        label="Se souvenir de moi"
                        class="mt-0"
                        @change="lsRememberMe"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <a
                        class="ms-3"
                        @click="openForgotPass"
                      > Mot de passe oublié? </a>
                    </div>

                    <v-btn
                      :disabled="isDialogVisible"
                      :loading="isDialogVisible"
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Connecter
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import store from '@/store'
import router from '@/router'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const form = ref(null)
    const email = ref('')
    const password = ref('')
    const message = ref('')
    const errors = ref('')
    const isDialogVisible = ref(false)
    const isChecked = ref(false)
    const isErrorMessage = ref(false)
    const isAlertVisible = ref(false)

    const rules = {
      password_required: true,
    }

    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const verifyRemember = () => {
      if (localStorage.checkbox) {
        isChecked.value = true
        email.value = localStorage.username
      } else {
        isChecked.value = false
        email.value = ''
      }
    }

    const lsRememberMe = () => {
      if (isChecked.value && email.value !== '') {
        localStorage.setItem('username', email.value)
        localStorage.setItem('checkbox', isChecked.value)
      } else {
        localStorage.setItem('username', '')
        localStorage.setItem('checkbox', false)
      }
    }

    const login = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      store.dispatch('user/login', { login: email.value, password: password.value, device_key: localStorage.getItem('device_token') !== null ? localStorage.getItem('device_token') : '' })
        .then(() => {
          isDialogVisible.value = false
          router.push('/')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          rules.password_required = error.response.data.errors !== undefined && error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : ''
          isAlertVisible.value = true
          isDialogVisible.value = false
          isErrorMessage.value = true
        })
    }

    const openForgotPass = () => {
      router.push({ name: 'forgot-password' })
    }

    onMounted(verifyRemember)

    return {
      openForgotPass,
      isAlertVisible,
      isErrorMessage,
      form,
      login,
      message,
      lsRememberMe,
      isChecked,
      verifyRemember,
      isPasswordVisible,
      isDialogVisible,
      email,
      password,
      socialLink,
      rules,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
